<template>
  <div class="card-row tasks-block">
    <div class="tasks">
      <div v-for="(task, index) of timeline" :key="task.id" :class="task.status?.cssClass" class="tasks-item">
        <div class="tasks-item-header" v-if="!isReadonly">
          <p class="status">{{ renderStatus(task) }}</p>
          <IconComponent @click="editTimeline(task, index)" class="icon-mode tooltip-hover" name="mode" />
          <IconComponent @click="deleteTimeline(task, index)" class="icon-delete tooltip-hover" name="delete" />
        </div>
        <div class="arrow"></div>
        <p class="text">{{ task.name }}</p>
        <p class="date">{{ $momentFormat(task.date, 'DD MMMM YYYY') }}</p>
      </div>
    </div>
    <div class="tasks-added" v-if="!isReadonly">
      <div @click="addTimeline()" class="tasks-added_block">
        <IconComponent name="add_box" />
        <p>Создать новый шаг</p>
      </div>
    </div>
    <div class="mb-16">
      <ModalComponent :title="dialogTitle" @close="closeEditor" v-model="dialogEdit">
        <TimelineEditor
          v-bind:value="dialogItem"
          v-bind:updateValue="dialogNn"
          :statuses="statuses"
          v-on:save="updateTimeline($event)"
          v-on:close="closeEditor"
        />
      </ModalComponent>
    </div>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import IconComponent from '../../common/ui/IconComponent';
  import ModalComponent from '../modals/ModalComponent';
  import TimelineEditor from './TimelineEditor';
  export default {
    name: 'Timeline',
    components: { TimelineEditor, ModalComponent, IconComponent },
    mixins: [text],
    props: {
      timeline: Object,
      statuses: Array,
      isReadonly: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['save', 'remove'],
    data() {
      return {
        dialogTitle: 'Редактировать шаг',
        dialogIndex: -1,
        dialogItem: null,
        dialogEdit: false,
        dialogNn: 0,
      };
    },
    methods: {
      addTimeline() {
        this.dialogNn++;
        this.dialogIndex = -1;
        this.dialogItem = {
          id: 0,
          name: '',
          date: null,
          statusId: 1,
        };
        this.dialogTitle = 'Создать шаг';
        this.dialogEdit = true;
      },
      editTimeline(item, index) {
        this.dialogNn++;
        this.dialogIndex = index;
        this.dialogItem = Object.assign({}, item);
        this.dialogTitle = 'Редактировать шаг';
        this.dialogEdit = true;
      },
      updateTimeline(item) {
        this.closeEditor();
        this.$emit('save', item);
      },
      closeEditor() {
        this.dialogEdit = false;
      },
      deleteTimeline(item, index) {
        this.$emit('remove', { item: item, index: index });
      },
      renderStatus(item) {
        return this.truncate(item.status?.name, 50);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tasks {
    display: flex;
    overflow-x: auto;
    padding: 24px 0 12px 0;
    height: 252px;
    &-item {
      display: flex;
      flex-direction: column;
      min-width: 192px;
      min-height: 216px;
      max-width: 192px;
      max-height: 216px;
      padding: 8px 10px;
      background: #fbfbfb;
      border-radius: 4px;
      margin-right: 16px;
      &:last-of-type {
        margin-right: 200px;
      }
      p {
        font-family: 'Fira Sans';
        line-height: 130%;
      }
      .status {
        font-size: 12px;
      }
      .text {
        font-weight: 500;
        font-size: 14px;
      }
      .date {
        margin-top: auto;
        color: #787f8f;
      }
      .arrow {
        background-color: black;
        margin-bottom: 10px;
        height: 12px;
        width: 100%;
        position: relative;
        &:before,
        &:after {
          position: absolute;
          content: ' ';
          top: 0;
          height: 12px;
          width: 12px;
          transform: rotate(45deg);
          transition: 0.3s;
        }
        &:before {
          left: -10px;
          background-color: #fbfbfb;
        }
        &:after {
          top: 2px;
          right: -4px;
          height: 8px;
          width: 8px;
          background-color: black;
        }
      }
      &.green {
        .status,
        .text {
          color: #31bc00;
        }
        .arrow:after,
        .arrow {
          background-color: #31bc00;
        }
      }
      &.yellow {
        .status,
        .text {
          color: #e9c009;
        }
        .arrow:after,
        .arrow {
          background-color: #e9c009;
        }
      }
      &.gray {
        .status,
        .text {
          color: #787f8f;
        }
        .arrow:after,
        .arrow {
          background-color: #787f8f;
        }
      }
      &-header {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .icon {
          color: #dadada;
          &:nth-of-type(1) {
            margin-left: auto;
            margin-right: 24px;
          }
        }
      }
    }
    &-block {
      position: relative;
      min-height: 216px;
    }
    &-added {
      position: absolute;
      top: 20px;
      right: 0;
      max-width: 192px;
      max-height: 216px;
      width: 100%;
      height: 100%;
      background: #e6f5ffb0;
      box-shadow: 0 0 8px rgba(55, 55, 55, 0.08);
      padding: 8px;
      z-index: 2;
      &_block {
        cursor: pointer;
        width: 100%;
        height: 100%;
        border: 1px dashed #0184c2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        p {
          font-family: 'Fira Sans';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 160%;
          background: linear-gradient(270deg, #00b2aa 0%, #0078c8 100%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          color: #0181c3;
          margin-top: 10px;
        }
      }
    }
  }
  @media (max-width: 420px) {
    .tasks-item {
      min-width: 155px;
    }
    .tasks-added {
      max-width: unset;
      width: 40%;
      p {
        text-align: center;
      }
    }
  }
</style>
